import React from "react";
import styled from "styled-components";
import Logo from "@dls/assets/dist/images/logo_singtel.png";
import { Layout, Grid } from "@dls/web";

const LogoImage = styled.img`
  max-width: 100%;
  height: auto;
`;

const ImageContainer = styled.div`
  width: 90px;
  display: flex;
  align-items: center;
`;

const HeaderLine = styled.div`
  border-top: 4px solid #ed1a3d;
  box-shadow: 1px 2px 10px 1px #d6d6d6;
`;

const StyledGrid = styled(Grid)`
  padding: 0px 24px;
`;

const Header = () => {
  return (
    <HeaderLine>
      <StyledGrid fluid={false}>
        <Layout.Inline align="spaceBetween" alignY={"center"}>
          <ImageContainer>
            <LogoImage src={Logo} alt="Singtel Logo" />
          </ImageContainer>
        </Layout.Inline>
      </StyledGrid>
    </HeaderLine>
  );
};

export default Header;
