import { render } from "@testing-library/react";
import { PaymentContext } from "../context/paymentContext";
import React from "react";
import { isValidMobileNumber } from "@lux/helpers";
import CryptoJS from "crypto-js";
import Hex from "crypto-js/enc-hex";
import data from "./../locale/en.json";
import { requestHeaderMapping } from "./constants";

import {
  ERROR_CODES,
  PaymentSuccessMessages,
  oauthTokenAPI,
  DEFAULT_ERROR_PAGE
} from "./constants";
const defaultHeaders = { "Content-Type": "application/json" };

export const formatCC = (value) => {
  const matches = value.match(/.{1,4}/g);
  const formatted = matches.join(" ").replace(/x/g, "*");
  return formatted;
};

export const creditNumberErrorMessagesMap = (MPGS_CODES, MPGS_ERROR_MSGS) => {
  let map = new Map();
  map.set(MPGS_CODES.EMPTY, MPGS_ERROR_MSGS.INVALID_EMPTY);
  map.set(MPGS_CODES.INVALID_CHARACTERS, MPGS_ERROR_MSGS.INVALID_CARD);
  map.set(MPGS_CODES.INVALID_VALUE, MPGS_ERROR_MSGS.INVALID_CARD);
  map.set(MPGS_CODES.INVALID_LENGTH, MPGS_ERROR_MSGS.INVALID_CARD);
  map.set(MPGS_CODES.NOT_SUPPORTED, MPGS_ERROR_MSGS.INVALID_CARD);
  return map;
};

export const expiryMonthErrorMessagesMap = (MPGS_CODES, MPGS_ERROR_MSGS) => {
  let map = new Map();
  map.set(MPGS_CODES.EMPTY, MPGS_ERROR_MSGS.INVALID_EXPIRY_MONTH_EMPTY);
  map.set(MPGS_CODES.INVALID_CHARACTERS, MPGS_ERROR_MSGS.INVALID_EXPIRY_MONTH);
  map.set(MPGS_CODES.INVALID_VALUE, MPGS_ERROR_MSGS.INVALID_EXPIRY_MONTH);
  return map;
};

export const expiryYearErrorMessagesMap = (MPGS_CODES, MPGS_ERROR_MSGS) => {
  let map = new Map();
  map.set(MPGS_CODES.EMPTY, MPGS_ERROR_MSGS.INVALID_EXPIRY_YEAR_EMPTY);
  map.set(MPGS_CODES.INVALID_CHARACTERS, MPGS_ERROR_MSGS.INVALID_EXPIRY_YEAR);
  map.set(MPGS_CODES.INVALID_VALUE, MPGS_ERROR_MSGS.INVALID_EXPIRY_YEAR);
  map.set(MPGS_CODES.EXPIRED, MPGS_ERROR_MSGS.INVALID_EXPIRED);
  return map;
};

export const secuirtyCodeErrorMessagesMap = (MPGS_CODES, MPGS_ERROR_MSGS) => {
  let map = new Map();
  map.set(MPGS_CODES.INVALID_CHARACTERS, MPGS_ERROR_MSGS.INVALID_CVV);
  map.set(MPGS_CODES.INVALID_VALUE, MPGS_ERROR_MSGS.INVALID_CVV);
  return map;
};

export const isNullArray = (input) => {
  if (Array.isArray(input) && input.length) {
    return input.every((x) => x == null);
  } else {
    return false;
  }
};

export const getMerchantInfo = (instrumentType, instrumentInfo) => {
  if (instrumentType && Array.isArray(instrumentInfo)) {
    return instrumentInfo.find((item) => item.instrument === instrumentType);
  }
  return null;
};

export const constructRequestWithPayload = ({
  method = "get",
  body = {},
  headers = defaultHeaders
}) => {
  let params = {
    method,
    headers: headers
  };
  if (Object.keys(body).length > 0) {
    params.body = JSON.stringify(body);
  }
  return params;
};

export const removeWhiteSpacesFromString = (input) => {
  if (typeof input === "string") {
    return input.replace(/\s/g, "");
  }
  return input;
};

export const generateRedirectUrlForPaymentError = (err) => {
  let redirectUrl = "";
  if (isObject(err) && (err.error_code || err.sub_error_code)) {
    redirectUrl = `/paymentError?errorCode=${err.error_code}&subErrorCode=${err.sub_error_code}`;
  }
  return redirectUrl;
};

export const validatePin = (input) => {
  return input.length < 6 || isNaN(Number(input))
    ? data.payment_dash_login_pininvalid
    : true;
};

export const isObject = (value) => {
  let isObject =
    typeof value === "object" &&
    value !== null &&
    Array.isArray(value) === false;
  return isObject;
};

/*
  The below function gets the input with country code has prefix.
  Hence we split the string
*/
export const validateMobileNumber = (input) => {
  input = removeWhiteSpacesFromString(input);
  input = input.startsWith("+65") ? input.substr(3) : input;
  return isValidMobileNumber(input)
    ? true
    : data.payment_dash_login_mobilenumberinvalid;
};

export const gstDetailsText = (gstDetails) => {
  return gstDetails == 0 || gstDetails === undefined
    ? "Total"
    : `Total (inc. ${gstDetails}${data.payment_percentage_gst})`;
};

/*
  The below method would generate the SecretKey for the given value. 
*/

export const generateSecretKey = (value) => {
  if (value) {
    const timestamp = new Date().toISOString();
    const secretKey = value + timestamp;
    return [secretKey, timestamp];
  }
  return null;
};

export const combineRequestHeaders = (
  headers = {},
  defaultHeaders = { "Content-Type": "application/json" }
) => {
  if (Object.keys(headers).length) {
    return { ...headers, ...defaultHeaders };
  }
  return defaultHeaders;
};

export const generateRequestHeaders = (payload = {}) => {
  let requestObj = {};
  if (Object.keys(payload).length) {
    for (let [key, val] of Object.entries(payload)) {
      requestObj[requestHeaderMapping[key]] = val;
    }
  }
  return requestObj;
};

export const handleInvalidPaymentStatusError = ({ err, history }) => {
  if (isObject(err) && isObject(history)) {
    const redirectUrl = generateRedirectUrlForPaymentError(err);
    return redirectUrl && history.push(redirectUrl);
  }
};

export const handlePaymentErrors = ({ err, history }) => {
  if (isObject(err) && isObject(history)) {
    if (err.error_code === ERROR_CODES.retryExceeded) {
      history.push(`/paymentError?errorCode=${err.error_code}`);
      return true;
    }
    if (err.error_code === ERROR_CODES.invalidPaymentStatus) {
      handleInvalidPaymentStatusError({ err, history });
      return true;
    }
  }
  return false;
};

export const getInstrumentInfoByType = (paymentInfo, instrumentType) => {
  let dashInstrumentInfo = null;

  if (paymentInfo && paymentInfo.instrumentInfo) {
    dashInstrumentInfo = paymentInfo.instrumentInfo.filter((item) => {
      return (
        item.instrument === instrumentType && item.serviceAvailable !== false
      );
    });
    dashInstrumentInfo =
      (dashInstrumentInfo.length !== 0 && dashInstrumentInfo[0]) || null;
  }

  return dashInstrumentInfo;
};

export const getDeviceInformation = () => {
  let device = {
    browser: null,
    browserDetails: {
      javaEnabled: false,
      language: "en-US",
      screenHeight: 0,
      screenWidth: 0,
      timeZone: 0,
      colorDepth: 24
    }
  };

  try {
    device = {
      browser: window.navigator.userAgent,
      browserDetails: {
        javaEnabled: window.navigator.javaEnabled() || false,
        language: window.navigator.language || "en-US",
        screenHeight: window.screen.width,
        screenWidth: window.screen.height,
        timeZone: new Date().getTimezoneOffset(),
        colorDepth: window.screen.colorDepth
      }
    };
  } catch (e) {
    console.log(e);
  }
  return device;
};

export const storeManageTxDataDetails = ({
  isManageFlow = false,
  operationType = null
}) => {
  let txDetails = { isManageFlow, operationType };
  sessionStorage.setItem("manageCardDetails", JSON.stringify(txDetails));
};

export const getPaymentSuccessMessages = () => {
  const manageCardDetails =
    sessionStorage.getItem("manageCardDetails") || false;
  let intitalValues = {
    isManageFlow: false,
    operationType: "payment"
  };
  let manageFlow = intitalValues;
  if (!!manageCardDetails) {
    try {
      manageFlow = JSON.parse(manageCardDetails);
    } catch (e) {
      manageFlow = intitalValues;
    }
  }
  const defaultPaymentMessage = PaymentSuccessMessages.payment;
  const { isManageFlow, operationType } = manageFlow;
  return isManageFlow
    ? PaymentSuccessMessages[operationType] || defaultPaymentMessage
    : defaultPaymentMessage;
};

export const isManageCardErrorFlow = (routeName) => {
  return window.location.href.includes(routeName);
};

export const isPastYear = ({
  year = false,
  expiryYear = false,
  month = false,
  expiryMonth = false
}) => {
  if (year && expiryYear && month && expiryMonth) {
    year = Number(year);
    expiryYear = Number(expiryYear);
    month = Number(month);
    expiryMonth = Number(expiryMonth);
    return year <= expiryYear && month < expiryMonth;
  }
  return false;
};

export const getOauthToken = ({ paymentToken, correlationId }) => {
  let params = {
    method: "POST",
    headers: {
      "Content-Type": "application/x-www-form-urlencoded"
    },
    body: "grant_type=client_credentials"
  };

  let uid = process.env.REACT_APP_UD || false;
  let pwd = process.env.REACT_APP_PD || false;

  if (uid && pwd && paymentToken && correlationId) {
    params.headers.authorization = `Basic ${constructEncryptHeader({
      uid,
      pt: paymentToken,
      cid: correlationId,
      pwd
    })}`;
  }
  let url = `${oauthTokenAPI}?paymentToken=${paymentToken}&correlationId=${correlationId}`;
  return fetch(url, params);
};

export const getToken = async ({ paymentToken, correlationId }) => {
  const result = await getOauthToken({
    paymentToken,
    correlationId
  });
  if (result?.ok) {
    let response = await result.json();
    return response.access_token;
  } else {
    throw new Error("api failed to fetch token");
  }
};

export const constructEncryptHeader = ({
  pt = null,
  cid = null,
  uid = null,
  pwd = null
}) => {
  if (uid && pt && cid && pwd) return btoa(`${uid}${pt}:${pwd}${cid}`);
  return null;
};

export const constructAuthorizationHeaders = (oauthToken) => {
  const headers = Object.assign({}, defaultHeaders);
  if (oauthToken) {
    headers.authorization = `Bearer ${oauthToken}`;
  }
  return headers;
};

export const constructPaymentBaseUrl = ({
  url = null,
  correlationId = null,
  paymentToken = null
}) => {
  if (url && correlationId && paymentToken) {
    return `${url}?paymentToken=${paymentToken}&correlationId=${correlationId}`;
  }
  return null;
};

export const redirectToErrorPage = () => {
  const baseUrl = window.location.origin;
  baseUrl && window.location.assign(`${baseUrl}${DEFAULT_ERROR_PAGE}`);
};

export const isPaymentSuccessRoute = () => {
  const location = String(window.location && window.location.href);
  return (
    location.indexOf("paymentsuccess") !== -1 ||
    location.indexOf("errorpage") !== -1
  );
};

export const getCurrentPageUrl = () => {
  return window.location && window.location.href;
};

export function calculateHmacSHA256(message, secretKey) {
  // Convert the message and secret key to WordArrays (CryptoJS format)
  const messageWordArray = CryptoJS.enc.Utf8.parse(message);
  const secretKeyWordArray = CryptoJS.enc.Utf8.parse(secretKey);

  // Calculate the HMAC-SHA256 hash
  const hash = CryptoJS.HmacSHA256(messageWordArray, secretKeyWordArray);

  // Return the hash in a desired format (e.g., hex, base64)
  return hexToBase64(hash.toString(CryptoJS.enc.Hex)); // Or CryptoJS.enc.Base64
}

function hexToBase64(hexString) {
  // Remove whitespace and split the hex string into pairs
  const hexPairs = hexString.replace(/\s+/g, "").match(/.{1,2}/g);

  // Convert each pair from hex to decimal and then to character
  const chars = hexPairs.map((pair) => String.fromCharCode(parseInt(pair, 16)));

  // Use btoa to convert the resulting array of characters to Base64
  return btoa(chars.join(""));
}

/*
 @Param ts epoch timestamp
 this function returns date obj
 returns date obj
*/

function epochToJsDate(ts) {
  return new Date(ts * 1000);
}

export const validateInstrumentInfoResponse = (res) => {
  const { amount, gstDetails, instrumentInfo } = res;
  const responseValid = amount && gstDetails && instrumentInfo;
  const transactionsHaveValidInstruments = instrumentInfo.some(
    (i) => i.enabled === true
  );
  return responseValid && transactionsHaveValidInstruments;
};

export function storePaymentQueryParams(paymentQP, queryParams) {
  if (!paymentQP && queryParams?.paymentToken && queryParams?.correlationId) {
    const queryParamValue = `?paymentToken=${queryParams.paymentToken}&correlationId=${queryParams.correlationId}`;
    sessionStorage.setItem("paymentQP", queryParamValue);
    return true;
  }
  return false;
}

export const isMobileSDKFlow = () => {
  // Retrieve the value stored in sessionStorage for the key "isMobileSDKFlow"
  const isMobileFlow = sessionStorage.getItem("isMobileSDKFlow");
  if (isMobileFlow) {
    return true;
  } else {
    return false;
  }
};
