import React, { Suspense, lazy } from "react";

import {
  BrowserRouter as Router,
  Switch,
  Route,
  Redirect
} from "react-router-dom";
import { ROUTEPREFIX, errorRoute } from "./utils/constants";
import { UnionPayWindow } from "./containers";

import { GenericError, RouteWithLayout, Layout, Spinner } from "./components";
import PaymentProvider from "./context/paymentContext";
import LocaleProvider from "./context/localeContext";
import { SessionProvider } from "./context/sessionContext";
import ScrollToTop from "./components/ScrollToTop";

const PaymentSummary = lazy(() => import("./containers/PaymentSummary"));
const CreditCardPayment = lazy(() => import("./containers/CreditCardPayment"));
const PaymentStatus = lazy(() => import("./containers/PaymentStatus"));
const PaymentError = lazy(() => import("./containers/PaymentError"));
const PaymentSelection = lazy(() => import("./containers/PaymentSelection"));
const SavedCcPayment = lazy(() => import("./containers/SavedCcPayment"));
const EnetsPayment = lazy(() => import("./containers/EnetsPayment"));
const EGIROPage = lazy(() => import("./containers/EGIRO"));
const ManageInstruments = lazy(() => import("./containers/ManageInstruments"));
const RedirectSuccess = lazy(() => import("./containers/RedirectSuccess"));
const RedirecToMobileSDK = lazy(() =>
  import("./containers/RedirectToMobileSDK")
);
const Paynow = lazy(() => import("./containers/Paynow"));
const AXS = lazy(() => import("./containers/AXS"));
const TransactionResult = lazy(() => import("./containers/TransactionResult"));
const NavigatePayment = lazy(() => import("./containers/NavigatePayment"));
const EnetsWindowWithRouter = lazy(() =>
  import("./containers/EnetsPayment/EnetsWindow")
);
const DebugSDK = lazy(() => import("./containers/NavigatePayment/DebugSDK"));

import { ThemeProvider } from "@dls/web";
import { web } from "@dls/themes";

const theme = web.dlsStandard;

const reload = () => window.location.reload();

const routes = (
  <Router basename={ROUTEPREFIX}>
    <Suspense fallback={<Spinner />}>
      <ScrollToTop />
      <ThemeProvider theme={theme}>
        <SessionProvider>
          <LocaleProvider>
            <PaymentProvider>
              <Switch>
                <Route path="/paymentsummary" exact>
                  <PaymentSummary />
                </Route>
                <Route
                  path="/paymentselection"
                  exact
                  component={PaymentSelection}
                />
                <Route
                  layout={Layout}
                  path="/savedcc"
                  exact
                  component={SavedCcPayment}
                />
                {/* Redirect while preserving query params*/}
                <Route
                  path="/paymentmethod"
                  component={({ location }) => (
                    <Redirect
                      to={{
                        ...location,
                        pathname: location.pathname.replace(
                          /paymentmethod/,
                          "creditcard"
                        )
                      }}
                    />
                  )}
                />
                <Route path="/creditcard" exact>
                  <CreditCardPayment />
                </Route>
                <RouteWithLayout
                  layout={Layout}
                  path="/managecard"
                  component={ManageInstruments}
                />
                <RouteWithLayout
                  path={["/enetspay", "/bnpl", "/unionpay"]}
                  exact
                  layout={Layout}
                  component={EnetsPayment}
                />
                <RouteWithLayout
                  path="/egiro"
                  layout={Layout}
                  component={EGIROPage}
                />
                <RouteWithLayout
                  path="/navigatepayment"
                  layout={Layout}
                  component={NavigatePayment}
                  hideHeader={true}
                />
                <RouteWithLayout
                  path="/debugsdk"
                  layout={Layout}
                  component={DebugSDK}
                  hideHeader={true}
                />
                <RouteWithLayout
                  layout={Layout}
                  path={["/paynow", "/gxs"]}
                  component={Paynow}
                />
                <RouteWithLayout layout={Layout} path="/axs" component={AXS} />
                <Route
                  layout={Layout}
                  path="/transaction/status"
                  component={TransactionResult}
                />
                <Route path="/paymentsuccess" exact>
                  <PaymentStatus />
                </Route>
                <Route path="/redirectsuccess" exact>
                  <RedirectSuccess />
                </Route>
                <Route path="/redirectosdk" exact>
                  <RedirecToMobileSDK />
                </Route>
                <Route exact path={["/paymenterror", "/managecarderror"]}>
                  <PaymentError />
                </Route>
                <RouteWithLayout
                  layout={Layout}
                  path={errorRoute}
                  component={GenericError}
                  alignEntrust={"center"}
                />
                <Route path="/enetswindow" exact>
                  <EnetsWindowWithRouter />
                </Route>
                <Route path="/unionpaywindow" exact>
                  <UnionPayWindow />
                </Route>
                <Route path="/acs_v1.html" onEnter={reload} />
                <RouteWithLayout
                  layout={Layout}
                  component={GenericError}
                  alignEntrust={"center"}
                />
              </Switch>
            </PaymentProvider>
          </LocaleProvider>
        </SessionProvider>
      </ThemeProvider>
    </Suspense>
  </Router>
);

export default routes;
