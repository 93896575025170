/* istanbul ignore file */
import React from "react";
import { Route } from "react-router-dom";

const RouteWithLayout = ({
  layout: Layout,
  component: Component,
  alignEntrust,
  hideHeader,
  ...rest
}) => {
  return (
    <Route
      {...rest}
      render={(props) => (
        <Layout {...props} alignEntrust={alignEntrust} hideHeader={hideHeader}>
          <Component {...props} />
        </Layout>
      )}
    />
  );
};

export default RouteWithLayout;
