import React from "react";
import Header from "./../Header";
import Footer from "../Footer";
import styled from "styled-components";

const LayoutMainContainer = styled.div`
  min-height: 100vh;
  display: flex;
  flex-direction: column;
`;

const StyledLayoutChildren = styled.div`
  flex: "1 0 auto";
`;

const Layout = ({ children, footer = true, hideHeader = false }) => {
  let isMobileSDKFlow = null;
  try {
    isMobileSDKFlow = JSON.parse(
      sessionStorage.getItem("isMobileSDKFlow") || false
    );
  } catch (e) {
    isMobileSDKFlow = false;
  }

  return (
    <LayoutMainContainer>
      {isMobileSDKFlow || hideHeader ? null : <Header />}
      <StyledLayoutChildren>{children}</StyledLayoutChildren>
      {footer && <Footer />}
    </LayoutMainContainer>
  );
};

export default Layout;
