import { SOURCE_NAME } from '../../constants';

declare global {
  interface Window {
    ReactNativeWebView: object;
  }
}

type Subscribers = {
  [key: string]: {
    [key: string]: (data: any) => void;
  };
};

class PublishSubscribe {
  subscribers: Subscribers;
  webView: any;
  global: any = typeof window !== 'undefined' ? window : null;
  globalDocument: any = typeof document !== 'undefined' ? document : null;
  isAndroidDevice = this.global?.navigator?.appVersion?.includes('Android');
  public constructor() {
    this.subscribers = {};
    this.webView = this.global ? this.global.ReactNativeWebView : null;
    this.init();
  }

  private init() {
    const ref =
      this.webView && this.isAndroidDevice ? this.globalDocument : this.global;
    if (!ref?.addEventListener) return;
    ref.addEventListener(
      'message',
      (event: MessageEvent) => {
        const d = event as MessageEvent;
        if (typeof d?.data === 'string') {
          this.onMessage(d.data);
        }
      },
      false
    );
  }

  /**
   * @param {String} event
   */
  private onMessage(event: string) {
    try {
      const { topic, data, source } = JSON.parse(event);
      if (topic && data && source === SOURCE_NAME) {
        this.publish(topic, data, true);
      }
    } catch (e) {
      console.error('Issue with onMessage ' + (e && e.message));
    }
  }

  /**
   * @param {String} topic
   * @param {*=} data
   * @param {Boolean} isFromPostMsg
   */
  public publish(topic: string, data: object, isFromPostMsg = false) {
    // If it's a webview sends data to both instance level and post message
    if (this.webView) {
      this.webView.postMessage(data);
    }
    // If it's an iframe sends data to both instance level and post message
    if (!isFromPostMsg && window.parent) {
      window.parent.postMessage(JSON.stringify({ topic, data }), '*');
    }
    for (const sub in this.subscribers[topic]) {
      try {
        this.subscribers[topic][sub](data);
      } catch (e) {
        console.error(
          'Issue with subscriber ' + topic + '. ' + (e && e.message)
        );
      }
    }
  }

  /**
   * @param {String} topic
   * @param {function} subscriber
   */
  public subscribe(topic: string, subscriber: () => void) {
    const subKey = new Date().getTime();
    if (this.subscribers && this.subscribers[topic]) {
      this.subscribers[topic][subKey] = subscriber;
    } else {
      this.subscribers[topic] = {};
      this.subscribers[topic][subKey] = subscriber;
    }
    return subKey;
  }

  /**
   * @param {String} topic
   * @param {Number} key
   */
  public unsubscribe(topic: string, subKey: number) {
    if (subKey) {
      delete this.subscribers[topic][subKey];
    } else {
      delete this.subscribers[topic];
    }
  }

  public unsubscribeAll() {
    this.subscribers = {};
  }
}

export { PublishSubscribe };
