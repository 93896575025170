import { useCallback, useEffect, useState, useRef } from 'react';
import { SOURCE_NAME } from '../../constants';
import { PublishSubscribe } from '../../core/PublishSubscribe';

let pubSub: PublishSubscribe;

function usePubSub(topic: string) {
  const [subKeyList, setSubKey] = useState([]);
  const keyRef = useRef(subKeyList);

  useEffect(() => {
    pubSub = pubSub ? pubSub : new PublishSubscribe();
  }, []);

  useEffect(() => {
    keyRef.current = subKeyList;
  }, [subKeyList]);

  const publishToRef = useCallback((ref, data) => {
    ref?.postMessage(JSON.stringify({ source: SOURCE_NAME, topic, data }), '*');
  }, []);

  const unsubscribe = useCallback(
    (subKey) => {
      pubSub.unsubscribe(topic, subKey);
      //remove subKey from list
      const list = [...subKeyList];
      const index = list.findIndex((it) => it === subKey);
      if (index > -1) {
        list.splice(index, 1);
        setSubKey(list);
      }
    },
    [subKeyList]
  );

  const subscribe = useCallback((cb) => {
    const key = pubSub.subscribe(topic, cb);
    setSubKey((prev) => {
      return [...prev, key];
    });
    return key;
  }, []);

  const publish = useCallback((data) => {
    pubSub.publish(topic, data);
  }, []);

  useEffect(() => {
    return () => {
      //unsubscribe on component unmount
      keyRef.current.forEach((key) => {
        key && unsubscribe(key);
      });
    };
  }, []);

  return {
    publish,
    publishToRef,
    subscribe,
    unsubscribe
  };
}
export { usePubSub };
