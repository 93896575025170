import React, { createContext, useState, useContext, useEffect } from "react";
import { useHistory } from "react-router-dom";
import { ERROR_CODES } from "./../utils/constants";

// Create context for session management
export const SessionContext = createContext();

export const useSession = () => useContext(SessionContext);

export const SessionProvider = ({ children }) => {
  const [sessionStartTime, setSessionStartTime] = useState(null);
  const [remainingTime, setRemainingTime] = useState(null);
  const navigate = useHistory();

  useEffect(() => {
    if (sessionStartTime) {
      const sessionDuration = 15 * 60 * 1000; // 15 minutes in milliseconds
      const sessionEndTime =
        new Date(sessionStartTime).getTime() + sessionDuration;

      const checkSessionExpiry = () => {
        const now = new Date().getTime();
        const remaining = sessionEndTime - now;

        setRemainingTime(remaining);

        if (remaining <= 0) {
          // Session expired, navigate to error page
          navigate.push(
            `/paymentError?errorCode=${ERROR_CODES.sessionExpired}`
          );
        }
      };

      // Check session expiration every second
      const intervalId = setInterval(checkSessionExpiry, 1000);

      // Cleanup interval on component unmount
      return () => clearInterval(intervalId);
    }
  }, [sessionStartTime, navigate]);

  return (
    <SessionContext.Provider value={{ setSessionStartTime, remainingTime }}>
      {children}
    </SessionContext.Provider>
  );
};
