import React from "react";
import styled from "styled-components";
import { Animation, Text } from "@dls/web";
import loading from "@dls/assets/dist/lottiefiles/loading";

export const SpinnerContainer = styled.div`
  position: fixed;
  display: flex;
  justify-content: center;
  top: 0;
  z-index: 9;
  height: 100%;
  width: 100%;
  right: 0;
  & > h2 {
    margin-top: 10px;
  }
`;

const AlignContainer = styled.div`
  margin: auto;
  display: flex;
  align-items: center;
  justify-items: center;
  flex-direction: column;
`;

const Spinner = ({ spinnerText = "" }) => (
  <SpinnerContainer>
    <AlignContainer>
      <Animation loop={true} action="play" source={loading} />
      {spinnerText && <Text type="pageTitle">{spinnerText}</Text>}
    </AlignContainer>
  </SpinnerContainer>
);

export default Spinner;
