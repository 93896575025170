import URI from 'urijs';

type EnvHosts = {
  [name: string]: string;
};

type AppSchemes = {
  [name: string]: string;
};

type DeepLinkConfig = {
  webUrl: string;
  envHosts: EnvHosts;
  appSchemes: AppSchemes;
};

/**
 * Get App deep link URL from a given web URL
 *
 * @param string - Web URL which includes query param 'path' to indicate the app deep link
 *  e.g. - https://msta.digital.singtel.com/pages/deeplink.html?path=app/usage/local/addons/all/detail&addon_id=DATA_PASS_GROUP
 * @param Type EnvHosts - Object map with environment name as the key and environment host as the value
 * @param Type AppSchemes - Object map with environment name as the key and app scheme name for that environment as the value
 *
 * @returns string - App deep link URL
 *  e.g. - mysingtel://app/usage/local/addons/all/detail?addon_id=DATA_PASS_GROUP
 */
export const getAppDeepLinkUrl = (deepLinkConfig: DeepLinkConfig) => {
  const { webUrl, envHosts, appSchemes } = deepLinkConfig;

  if (!webUrl || !envHosts || !appSchemes) {
    return '';
  }
  const uri = URI(webUrl);

  let env = '';
  for (const [key, value] of Object.entries(envHosts)) {
    if (value === uri.host()) {
      env = key;
      break;
    }
  }
  const { path, ...params } = uri.search(true);

  if (!path || !env || !appSchemes[env]) {
    return '';
  }

  const appUrl = `${appSchemes[env]}://${path}?${URI.buildQuery(params)}`;
  return appUrl;
};

/**
 * Determine whether the web page is accessed within app web view. We check whether
 * ReactNativeWebView object is available in window to determine this.
 *
 * @returns boolean
 */
export const isWebPageEmbeddedInApp = () => {
  return typeof window.ReactNativeWebView !== 'undefined';
};
